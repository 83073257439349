
import { defineComponent, onMounted, ref, inject, computed } from "vue";
import { useToast } from "vue-toastification";

import InputLabel from "@/components/atoms/InputLabel.vue";

// services
import emailService from "@/services/email.service";
import { AxiosError } from "axios";

export default defineComponent({
  components: {
    InputLabel,
  },
  setup() {
    const toast = useToast();
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    const setIsLoading = inject("setIsLoading") as CallableFunction;
    onMounted(() => setPageTitle("Customize Email"));

    // get email template
    const header = ref<string>("");
    const body = ref<string>("");
    const defaultBody = ref<string>("");
    onMounted(async () => {
      setIsLoading(true);
      const { data } = await emailService.getEmailTemplate();
      header.value = data.subject;
      body.value = data.body;
      defaultBody.value = data.default_body;

      setIsLoading(false);
    });

    const EMPTY_BODY = "<p><br></p>";
    const canSubmit = computed(
      () => header.value && body.value && body.value !== EMPTY_BODY
    );
    const reset = () => {
      body.value = defaultBody.value;
    };

    const saveEmail = async () => {
      try {
        setIsLoading(true);
        const req = {
          subject: header.value,
          body: body.value,
        };

        await emailService.saveEmailTemplate(req);
        setIsLoading(false);
        toast.success("Email template berhasil disimpan");
      } catch (e) {
        console.error(e);
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    return {
      header,
      body,
      canSubmit,
      reset,
      saveEmail,
    };
  },
});
