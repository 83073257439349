import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getEmailTemplate = async (): Promise<any> => {
  const { data } = await httpClient.get(`/b-client/v1/email/template`, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const saveEmailTemplate = async (req: any): Promise<any> => {
  const { data } = await httpClient.put("/b-client/v1/email/template", req, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

export default {
  getEmailTemplate,
  saveEmailTemplate,
};
